import { push } from 'react-router-redux';
import { notificationActionCreators } from '../../tabs/components/Notification';
import { progressActionCreators } from '../../tabs/components/Progress';
import * as constants from '../../common/Constants';
import * as microsoftTeams from "@microsoft/teams-js";

import {
    ICommunity,
    CommunityType
    //CommunityRequestOptions
} from './Community.types';
import actions from './Community.actions';
import { BRANDNAME } from '../../common/Tooltips';
import { getGTApiTokenAsync, getGTApiToken } from "../../common/auth";
import config from '../../config';

const FileSaver = require('file-saver');

// const MESSAGE_COMMUNITY_SAVE =
//     "Success! We've received your request to update your community details. It might take about an hour for your changes to be updated across all Microsoft systems.";
// const MESSAGE_COMMUNITY_SAVE_ERROR =
//     "Updating community '%DISPLAYNAME%' has failed' %ERROR%";
const MESSAGE_COMMUNITY_CLOSE =
    `Your community '%DISPLAYNAME%' has been deleted. The community will no longer be available in ${BRANDNAME} and Microsoft Office 365.`;
const MESSAGE_COMMUNITY_CLOSE_ERROR =
    "Closing community '%DISPLAYNAME%' has failed' Error: %ERROR%";

export const communityActionCreators = {
    setFormIsDirty: () => (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_FORM_DIRTY
        });
    },

    acceptedTnCCommunity: () => async (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_TNC_ACCEPTED
        });
    },

    showCommunityMembers: () => async (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_MEMBERS_SHOW,
        });
    },

    hideCommunityMembers: () => async (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_MEMBERS_HIDE,
        });
    },

    startEditManage: (community) => async (dispatch, getState) => {
        if (community === null) {
            dispatch({
                type: actions.COMMUNITY_EDIT_START_CREATE
            });
            dispatch(push(constants.SCREEN_COMMUNITY));
        } else {
            dispatch({
                type: actions.COMMUNITY_MEMBERS_HIDE,
            });
            dispatch({
                type: actions.COMMUNITY_MANAGE_START,
                communityId: community.communityId,
                doShowMembers: false,
                initialValues: community
            });
            // dispatch(
            //     push(`${constants.SCREEN_COMMUNITY}/${community.communityAzureId}`)
            // );
        }
    },

    endEditManage: () => async (dispatch, getState) => {
        dispatch({ type: actions.COMMUNITY_MANAGE_END });
        dispatch(push(constants.SCREEN_DASHBOARD));
    },

    startEditCommunity: (community: ICommunity) => async (dispatch, getState) => {
        if (community == null) {
            dispatch({
                type: actions.COMMUNITY_EDIT_START_CREATE
            });
            dispatch(push(constants.SCREEN_COMMUNITY));
        } else {
            dispatch({
                type: actions.COMMUNITY_EDIT_START,
                communityId: community.communityId,
                initialValues: community
            });
        }
    },

    endEditCommunity: (communityId) => async (dispatch, getState) => {
        if (communityId) {
            if (getState().community.isDirty) {
                dispatch(communityActionCreators.requestFetchCommunity(communityId));
            }
            dispatch({
                type: actions.COMMUNITY_EDIT_END
            });
        } else {
            if (getState().community.isDirty) {
                //dispatch(communityActionCreators.requestFetchCommunities());
            }

            dispatch(push(constants.SCREEN_DASHBOARD));
        }
    },

    exportCommunityMemberCsv: (communityId) => async (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_MEMBERS_EXPORTCSV_REQUEST
        });

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );

        try {
            const response: any = async () =>
                await fetch(config.apiBaseUrl+ `/members/${communityId}/members.csv`, {
                    headers: {
                        Authorization: 'Bearer ' + apiToken,
                        Accept: 'text/csv'
                    }
                })
                    .then((res) => res.blob())
                    .then((blob) => FileSaver.saveAs(blob, 'members.csv'));

            response();

            dispatch({
                type: actions.COMMUNITY_MEMBERS_EXPORTCSV_RESPONSE
            });
        } catch (error) {
            dispatch({
                type: actions.COMMUNITY_MEMBERS_EXPORTCSV_RESPONSE,
                error
            });
        }
    },

    exportCommunityInvitesCsv: (communityId) => async (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_INVITES_EXPORTCSV_REQUEST
        });

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );

        try {
            const response: any = async () =>
                await fetch(config.apiBaseUrl + `/invites/${communityId}/invitations.csv`, {
                    headers: {
                        Authorization: 'Bearer ' + apiToken,
                        Accept: 'text/csv'
                    }
                })
                    .then((res) => res.blob())
                    .then((blob) => FileSaver.saveAs(blob, 'invitations.csv'));

            response();

            dispatch({
                type: actions.COMMUNITY_INVITES_EXPORTCSV_RESPONSE
            });
        } catch (error) {
            dispatch({
                type: actions.COMMUNITY_INVITES_EXPORTCSV_RESPONSE,
                error
            });
        }
    },

    /**
     * Fetch community using Azure GUID
     */
     requestFetchCommunity: (communityAzureId, loadTags: boolean = false, apiToken: string = null) => async (
         dispatch,
         getState
     ) => {
         if (getState().community.isLoading) {
             return;
         }
    
         dispatch({ type: actions.COMMUNITY_FETCH_REQUEST });
         dispatch(progressActionCreators.startShowProgress('Fetching community'));
         const auth = getState().auth;

         let _apiToken = apiToken || auth?.apiToken;
         if ( !_apiToken ) {
             _apiToken = await getGTApiToken(
                 auth.teamsToken
             );
         }


         try {
             const response: any = await fetch(
                 config.apiBaseUrl + `/communities/${communityAzureId}`,
                 {
                     headers: {
                         Authorization: 'Bearer ' + _apiToken,
                         'Content-Type': 'application/json'
                     }
                 }
             );
    
             const initialValues: ICommunity = await response.json();
    
             /**
              * Subsequent request to use internal ID
              */
             if (loadTags) {
                 const tagResponse: any = await fetch(
                     config.apiBaseUrl + `/communities/${initialValues.communityId}/tags`,
                     {
                         headers: {
                             Authorization: 'Bearer ' + _apiToken,
                             'Content-Type': 'application/json'
                         }
                     }
                 );
    
                 const tags: any = await tagResponse.json();
                 initialValues.tags = tags;
             }
    
             dispatch({
                 type: actions.COMMUNITY_FETCH_RESPONSE,
                 communityId: initialValues.communityId,
                 initialValues
             });
             dispatch(progressActionCreators.endShowProgress());
         } catch (error) {
             //dispatch(
             //    notificationActionCreators.startShowNotification(
             //        constants.ERROR_COMMUNITY_FETCH
             //    )
             //);
             //dispatch({ type: actions.COMMUNITY_FETCH_RESPONSE, error });
             //dispatch(progressActionCreators.endShowProgress());
         }
     },

    requestSaveCommunity: (communityId) => async (dispatch, getState) => {
        if (getState().community.isLoading) {
            return;
        }
        
        dispatch({ type: actions.COMMUNITY_SAVE_REQUEST });
        dispatch(progressActionCreators.startShowProgress('Saving community'));

        const communityForm = getState().form.CommunityForm.values;

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );

        if (communityId) {
            try {
                const response = await fetch(
                    config.apiBaseUrl + `/communities/${communityId}`,
                    {
                        method: 'put',
                        body: JSON.stringify(communityForm),
                        headers: {
                            Authorization: 'Bearer ' + apiToken,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!response.ok) {
                    throw response;
                }
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: true,
                    isDirty: true,
                    error: ''
                });
                /**
                 * To do: check reinitialisation after saving
                 *
                 */
                const existingValues: ICommunity = getState().community.initialValues;
                const initialValues: ICommunity = {
                    communityAzureId: existingValues.communityAzureId,
                    communityId: existingValues.communityId,
                    communityType: CommunityType[communityForm.typeId],
                    iconPreferenceId: communityForm.iconPreferenceId,
                    isCreated: existingValues.isCreated,
                    isFavourite: existingValues.isFavourite,
                    isMember: existingValues.isMember,
                    isOwner: existingValues.isOwner,
                    communityMembershipId: existingValues.communityMembershipId,
                    displayName: communityForm.displayName,
                    url: existingValues.url,
                    totalMembers: existingValues.totalMembers,
                    pendingRequests: existingValues.pendingRequests,
                    description: communityForm.description,
                    extendedDescription: communityForm.extendedDescription,
                    includeExtendedDescToWelcomeEmail: existingValues.includeExtendedDescToWelcomeEmail,
                    sharePointUrl: existingValues.sharePointUrl,
                    sponsored: existingValues.sponsored,
                    tags: []
                };

                communityForm.tags.forEach((tag: any) => {
                    initialValues.tags.push('#' + tag.name);
                });

                dispatch({
                    type: actions.COMMUNITY_MANAGE_START,
                    communityId,
                    initialValues
                });
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: true,
                    isDirty: false
                });
                dispatch(communityActionCreators.endEditCommunity(communityId));
                // dispatch(
                //     notificationActionCreators.startShowNotification(
                //         "MESSAGE_COMMUNITY_SAVE"
                //     )
                // );
            } catch (response) {
                const json = await response.json();
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: false,
                    isDirty: true,
                    error: json
                });
                // dispatch(
                //     notificationActionCreators.startShowNotification(
                //         MESSAGE_COMMUNITY_SAVE_ERROR.replace(
                //             '%DISPLAYNAME%',
                //             getState().community.initialValues.displayName
                //         ).replace('%ERROR%', json)
                //     )
                // );
            }

            dispatch(progressActionCreators.endShowProgress());
        } else {
            dispatch(
                progressActionCreators.startShowProgress('Creating new community...')
            );

            try {
                const response = await fetch(config.apiBaseUrl + `/communities`, {
                    method: 'post',
                    body: JSON.stringify(communityForm),
                    headers: {
                        Authorization: 'Bearer ' + apiToken,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw response;
                }
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: true,
                    isDirty: true,
                    error: ''
                });
                dispatch(progressActionCreators.endShowProgress());
            } catch (error) {
                const json = await error.json();
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: false,
                    isDirty: true,
                    error: json
                });
                dispatch(progressActionCreators.endShowProgress());
            }
        }
    },

     requestCloseCommunity: (communityId) => async (dispatch, getState) => {
         if (getState().community.isLoading) {
             return;
         }
    
         dispatch({ type: actions.COMMUNITY_CLOSE_REQUEST });

         getGTApiTokenAsync(getState().auth.teamsToken).then(
             async (apiToken: string) => {
                 if (communityId) {
                     dispatch(
                         progressActionCreators.startShowProgress('Closing community...')
                     );
                     try {
                         const response = await fetch(
                             config.apiBaseUrl + `/communities/${communityId}/close`,
                             {
                                 headers: {
                                     Authorization: 'Bearer ' + apiToken,
                                     'Content-Type': 'application/json'
                                 }
                             }
                         );
                         if (!response.ok) {
                             throw response;
                         }
                         dispatch(progressActionCreators.endShowProgress());
                         dispatch({
                             type: actions.COMMUNITY_CLOSE_RESPONSE,
                             isDirty: true
                         });
                        
                         dispatch(
                             notificationActionCreators.startShowNotification(
                                 MESSAGE_COMMUNITY_CLOSE.replace(
                                     '%DISPLAYNAME%',
                                     getState().community.initialValues.displayName
                                 )
                             )
                         );

                         microsoftTeams?.app?.openLink(config.govTeamsTab)
                             .then(null);

                         //dispatch(push(constants.WAFFLE_TEAMS));
                         //dispatch(communityActionCreators.requestFetchCommunities());
                     } catch (response) {
                         const json = await response.json();
                         dispatch({
                             type: actions.COMMUNITY_SAVE_RESPONSE,
                             isDirty: false,
                             error: json
                         });
                         dispatch(
                             notificationActionCreators.startShowNotification(
                                 MESSAGE_COMMUNITY_CLOSE_ERROR.replace(
                                     '%DISPLAYNAME%',
                                     getState().community.initialValues.displayName
                                 ).replace('%ERROR%', json)
                             )
                         );
                         dispatch(progressActionCreators.endShowProgress());

                     }
                 }
             }
         );
     },
   
     updateMemberCommunities: (communityId) => async (dispatch, getState) => {
         let initialValues;
    
         if (getState().routing.location.pathname === '/Search') {
             const searchCommunities = getState().search.searchCommunities
                 ? getState().search.searchCommunities.slice()
                 : getState().search.searchCommunities;
    
             if (searchCommunities) {
                 initialValues = searchCommunities.filter(
                     (sc) => sc.communityId === communityId
                 )[0];
    
                 initialValues.isMember = true;
                 initialValues.totalMembers++;
    
                 dispatch({
                     type: actions.COMMUNITY_MANAGE_UPDATE,
                     initialValues: JSON.parse(JSON.stringify(initialValues))
                 });
             }
    
             dispatch({
                 type: actions.COMMUNITY_UPDATE
             });
    
             return;
         }
    
         let suggestedCommunities = getState().community.suggestedCommunities
             ? getState().community.suggestedCommunities.slice()
             : getState().community.suggestedCommunities;
    
         const suggestedCommunity = suggestedCommunities.filter(
             (community) => community.communityId === communityId
         )[0];
    
         if (suggestedCommunity) {
             suggestedCommunity.isMember = true;
             suggestedCommunity.totalMembers++;
             suggestedCommunities = suggestedCommunities.filter(
                 (community) => community.communityId !== communityId
             );
         }
    
         const memberCommunities = getState().community.memberCommunities
             ? getState().community.memberCommunities.slice()
             : getState().community.memberCommunities;
    
         memberCommunities.push(suggestedCommunity);
    
         if (suggestedCommunity) {
             initialValues = JSON.parse(JSON.stringify(suggestedCommunity));
         }
    
         dispatch({
             type: actions.MEMBERCOMMUNITY_UPDATE,
             memberCommunities,
             suggestedCommunities
         });
         dispatch({
             type: actions.COMMUNITY_MANAGE_UPDATE,
             initialValues
         });
     },

     updateSuggestedCommunities: (communityId) => async (dispatch, getState) => {
         let initialValues;
    
         if (getState().routing.location.pathname === '/Search') {
             const searchCommunities = getState().search.searchCommunities
                 ? getState().search.searchCommunities.slice()
                 : getState().search.searchCommunities;
    
             if (searchCommunities) {
                 initialValues = searchCommunities.filter(
                     (community) => community.communityId === communityId
                 )[0];
    
                 if (initialValues !== undefined) {
                     initialValues.isMember = false;
                     initialValues.totalMembers--;
    
                     /**
                      * Parse JSON again to trigger update
                      */
                     dispatch({
                         type: actions.COMMUNITY_MANAGE_UPDATE,
                         initialValues: JSON.parse(JSON.stringify(initialValues))
                     });
                 }
             }
    
             dispatch({
                 type: actions.COMMUNITY_UPDATE
             });
    
             return;
         }
    
         let memberCommunities = getState().community.memberCommunities
             ? getState().community.memberCommunities.slice()
             : getState().community.memberCommunities;
    
         const memberCommunity = memberCommunities.filter(
             (community) => community.communityId === communityId
         )[0];
    
         const suggestedCommunities = getState().community.suggestedCommunities
             ? getState().community.suggestedCommunities.slice()
             : getState().community.suggestedCommunities;
    
         if (memberCommunity !== undefined) {
             memberCommunity.isMember = false;
             memberCommunity.totalMembers--;
             suggestedCommunities.push(memberCommunity);
    
             /**
              * Force new value to trigger update
              */
             initialValues = JSON.parse(JSON.stringify(memberCommunity));
    
             dispatch({
                 type: actions.COMMUNITY_MANAGE_UPDATE,
                 initialValues
             });
         }
    
         memberCommunities = memberCommunities.filter(
             (community) => community.communityId !== communityId
         );
    
         dispatch({
             type: actions.MEMBERCOMMUNITY_UPDATE,
             suggestedCommunities,
             memberCommunities
         });
     },

    requestSimilarCommunities: () => async (dispatch, getState) => {
         if (getState().profile.isGuest) {
             return;
         }

        if (getState().community.isLoading) {
            return;
        }

        const communityForm = getState().form.CommunityForm.values;

        if (!communityForm) {
            return;
        }

        const displayName = communityForm.displayName
            ? communityForm.displayName
            : '';
        let tags = '';
        const similarCommunities: any[] = [];

        if (communityForm.tags && communityForm.tags.length > 0) {
            communityForm.tags.forEach((tag) => {
                tags += tag.name + ';';
            });
        }

        dispatch({ type: actions.SIMILARCOMMUNITIES_FETCH_REQUEST });

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );

        try {
            const response = await fetch(
                config.apiBaseUrl +
                `/communities/Similar?filter=${displayName}&projects=${tags}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + apiToken,
                        'Content-Type': 'application/json'
                    }
                }
            );

            const communities = await response.json();

            if (communities && communities.length > 0) {
                for (let i = 0; i < communities.length && i < 3; i++) {
                    const communityData: any = communities[i];
                    similarCommunities.push(communityData);
                }
            }

            dispatch({
                type: actions.SIMILARCOMMUNITIES_FETCH_RESPONSE,
                similarCommunities
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            // dispatch(
            //     notificationActionCreators.startShowNotification(
            //         constants.ERROR_SUGGESTEDCOMMUNITIES_FETCH
            //     )
            // );
            dispatch({
                type: actions.SIMILARCOMMUNITIES_FETCH_RESPONSE,
                error
            });
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    requestFetchSuggestedCommunity: () => async (dispatch, getState) => {
        // if (getState().profile.isGuest) {
        //     return;
        // }

        if (getState().community.isSuggestedLoading) {
            return;
        }

        dispatch({ type: actions.SUGGESTEDCOMMUNITY_FETCH_REQUEST });
        const suggestedCommunities: any[] = [];
        /*
             Suggested Communities were disabled on 11/7/2019.  Uncomment these sections to enable suggested communities again.
             */
        //const skills = getState().profile.skills ? getState().profile.skills : '';
        //const interests = getState().profile.interests
        //  ? getState().profile.interests
        //  : '';
        //const projects = getState().profile.projects
        //  ? getState().profile.projects
        //  : '';

        //const apiToken: string = await adalGetToken(
        //  authContext,
        //  adalConfig.endpoints.api.guid
        //);
        try {
            //const response = await fetch(
            //  API_URL() +
            //    `/communities/Suggested?skills=${skills}&interests=${interests}&projects=${projects}`,
            //  {
            //    headers: {
            //      Authorization: 'Bearer ' + apiToken,
            //      'Content-Type': 'application/json'
            //    }
            //  }
            //);
            //const communities = await response.json();

            //if (communities && communities.length > 1) {
            //  communities.forEach(community => {
            //    if (!community.isMember && !community.isOwner) {
            //      suggestedCommunities.push(community);
            //    }
            //  });
            //}

            dispatch({
                type: actions.SUGGESTEDCOMMUNITY_FETCH_RESPONSE,
                suggestedCommunities
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            // dispatch(
            //     notificationActionCreators.startShowNotification(
            //         constants.ERROR_SUGGESTEDCOMMUNITIES_FETCH
            //     )
            // );
            dispatch({
                type: actions.SUGGESTEDCOMMUNITY_FETCH_RESPONSE,
                error
            });
            dispatch(progressActionCreators.endShowProgress());
        }
    },

     viewCommunityPanel: (community) => (dispatch, getState) => {
         dispatch({
             type: actions.COMMUNITY_PANEL_VIEW_START,
             communityId: community.communityId,
             initialValues: community
         });
     },
    
     closeCommunityPanel: () => (dispatch, getState) => {
         dispatch({
             type: actions.COMMUNITY_PANEL_VIEW_END
         });
     }
};

//

export default communityActionCreators;
