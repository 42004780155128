/**
 * Form to invite members to community
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators } from '../../../../controllers/CommunityController';
import { inviteMembersActionCreators } from '../../../../controllers/InviteMembersController';

/**
 * React form field
 */
import InviteMembersPickerComponent from '@govteams/library/components/InviteMembersPickerComponent/';
import NormalPeoplePicker from '../../ReduxFormFields/NormalPeoplePicker';

/**
 * Constants
 */
import * as constants from '../../../../common/Constants';
import { isProtected } from '../../../../common/Utils';
import { BRANDNAME } from '../../../../common/Tooltips';

/**
 * Child component
 * */
import { authActionCreators } from '../../../../controllers/Auth';

const InviteMembersPickerForm = (props) => {
  const {
    communityId,
    isSubmitted,
    initialValues,
    invitedMembers,
    results,

    communityActionCreators,
    inviteMembersActionCreators,
  } = props;

  return (
    <InviteMembersPickerComponent
      communityId={communityId}
      brandName={BRANDNAME}
      isProtected={isProtected()}
      isSubmitted={isSubmitted}
      initialValues={initialValues}
      invitedMembers={invitedMembers}
      constants={constants}
      results={results}

      NormalPeoplePicker={NormalPeoplePicker}

      requestFetchCommunity={communityActionCreators.requestFetchCommunity}
      requestSaveMembers={inviteMembersActionCreators.requestSaveMembers}
      endEditInviteMembers={inviteMembersActionCreators.endEditInviteMembers}
      toggleBulkInvite={inviteMembersActionCreators.toggleBulkInvite}
    />
  );
};

const mapStateToProps = (state) => ({
  communityId: state.inviteMembers.communityId,
  initialValues: state.community.initialValues,
  isSubmitted: state.inviteMembers.isSubmitted,
  results: state.inviteMembers.results,
  isLimitOverdue: state.inviteMembers.isLimitOverdue
});

const mapDispatchToProps = (dispatch) => ({
  authActionCreators: bindActionCreators(authActionCreators, dispatch),
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
  inviteMembersActionCreators: bindActionCreators(inviteMembersActionCreators, dispatch),
});

const InviteMembersPicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteMembersPickerForm);

export default InviteMembersPicker;
