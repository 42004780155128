import actions from './auth.actions';

export const authActionCreators = {
    storeTeamsToken: (token) => async (dispatch, getState) => {
        dispatch({
            type: actions.SETTEAMSTOKEN,
            payload: token,
        });
    },
    storeApiToken: (token) => async (dispatch, getState) => {
        dispatch({
            type: actions.SETAPITOKEN,
            payload: token,
        });
    },
};

export default authActionCreators;