const config: any = {
    publicHostname: process.env.REACT_APP_PUBLIC_HOSTNAME,
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    packageName: process.env.REACT_APP_PACKAGE_NAME,
    tabAppId: process.env.REACT_APP_TAB_APP_ID,
    tabAppUri: process.env.REACT_APP_TAB_APP_URI,
    isProtected: process.env.REACT_APP_ISPROTECTED,
    clientId: process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    scopes: process.env.REACT_APP_SCOPES,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    govTeamsTab: 'https://teams.microsoft.com/l/entity/d2c6f111-ffad-42a0-b65e-ee00425598aa/sharepointportal_d2c6f111-ffad-42a0-b65e-ee00425598aa',
    govTeamsTabClassic: 'https://teams.microsoft.com/_#/apps/d2c6f111-ffad-42a0-b65e-ee00425598aa/sections/sharepointportal_d2c6f111-ffad-42a0-b65e-ee00425598aa',
};
export default config;