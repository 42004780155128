/**
 * List of invitations sent from a community
 *
 * Used on the community management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Fabric UI
 */
import { List } from '@fluentui/react/lib/List';
import { IconButton } from '@fluentui/react/lib/Button';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';


import {
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup
} from '@fluentui/react/lib/Shimmer';

/**
 * Constants
 */
import * as tooltips from '../../../common/Tooltips';
// const dateFormat = require('dateformat');

export class Invites extends React.Component<any, any> {

  public render() {
    return (
      <Shimmer
        width={'100%'}
        styles={{
          root: {
            marginTop: 20
          }
        }}
        isDataLoaded={this.props.invitations !== null}
        customElementsGroup={
          <ShimmerElementsGroup
            flexWrap={true}
            width={`100%`}
            shimmerElements={[
              { type: ShimmerElementType.line, width: '100%', height: 20 },
              { type: ShimmerElementType.gap, width: '100%' },
              { type: ShimmerElementType.line, width: '100%', height: 20 },
              { type: ShimmerElementType.gap, width: '100%' },
              { type: ShimmerElementType.line, width: '100%', height: 20 }
            ]}
          />
        }
      >
        {this.props.invitations && this.props.invitations.length > 0 ? (
          <List
            items={this.props.invitations}
            onRenderCell={this.onRenderCell}
          />
        ) : (
          <div>There are no {this.props.inviteType} invitations. </div>
        )}
      </Shimmer>
    );
  }

  private onSendReminder = (event) => {
    this.props.invitesActionCreators.requestResendInvite(
      event.currentTarget.id
    );
  };

  private onDecline = (event) => {
    this.props.invitesActionCreators.requestDeleteInvite(
      event.currentTarget.id
    );
  };

  private onRenderCell = (item, index, isScrolling) => {

    return (
      <div className='memberItem' data-is-focusable={true}>
        <div className='memberPersona'>
          <div className='ms-Persona-primaryText'>{item.emailAddress}</div>
                <div className='ms-Persona-secondaryText'>
                    
            {item.isGuest ? 'Guest invited on' : (item.isLAM ? 'External member invited on' : 'FAM invited on')}{' '}
            {item.inviteDate}
          </div>
        </div>
        <div className='memberTools'>
          <TooltipHost content={tooltips.INVITETOOLS_RESEND}>
            <IconButton
              id={item.id}
              iconProps={{ iconName: 'ReplyMirrored' }}
              onClick={this.onSendReminder}
            />
          </TooltipHost>
          <span className='sep' />
          <TooltipHost content={tooltips.INVITETOOLS_CANCEL}>
            <IconButton
              id={item.id}
              className='memberItem-remove'
              iconProps={{ iconName: 'StatusErrorFull' }}
              onClick={this.onDecline}
            />
          </TooltipHost>
        </div>
      </div>
    );
  };
}
