import config from '../config';

export const sortItemsByInt = (items, sortBy, descending = false) => {
    if (descending) {
        return items.sort((a, b) => {
            return b[sortBy] - a[sortBy];
        });
    } else {
        return items.sort((a, b) => {
            return a[sortBy] - b[sortBy];
        });
    }
};

export const sortItemsByIntProperty = (
    items,
    sortBy,
    descending = false
) => {
    if (descending) {
        return items.sort((a, b) => {

            if (a[sortBy] < b[sortBy]) {
                return 1;
            }
            if (a[sortBy] > b[sortBy]) {
                return -1;
            }
            return 0;
        });
    } else {
        return items.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
                return -1;
            }
            if (a[sortBy] > b[sortBy]) {
                return 1;
            }
            return 0;
        });
    }
};

export const sortItems = (
    items,
    sortBy,
    descending = false,
    boolean = false
) => {
    if (descending) {
        return items.sort((a, b) => {
            const valueA = !boolean && a[sortBy] ? a[sortBy].toLowerCase() : a[sortBy];
            const valueB = !boolean && b[sortBy] ? b[sortBy].toLowerCase() : b[sortBy];

            if (valueA < valueB) {
                return 1;
            }
            if (valueA > valueB) {
                return -1;
            }
            return 0;
        });
    } else {
        return items.sort((a, b) => {
            const valueA = !boolean && a[sortBy] ? a[sortBy].toLowerCase() : a[sortBy];
            const valueB = !boolean && b[sortBy] ? b[sortBy].toLowerCase() : b[sortBy];

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        });
    }
};

export function getFormattedDateTime(
    dateTime: string,
    options = {},
    format = 'en-AU',
    timeZone = ''
): string {
    /**
     * Split date and reconstitute to workaround difference betweem browser dates
     */
    if (dateTime) {
        const arr: any[] = dateTime.split(/[^0-9]/);
        let newDate: any = new Date(
            arr[0],
            arr[1] - 1,
            arr[2],
            arr[3],
            arr[4],
            arr[5]
        );

        if (timeZone === 'UTC') {
            newDate = newDate.valueOf() - new Date().getTimezoneOffset() * 60000;
        }

        const formattedDateTime = new Intl.DateTimeFormat(format, options).format(
            newDate
        );

        return formattedDateTime;
    }

    return '';
}

export function isProtected() {
  return config.isProtected === 'true';
}

const _default = {
    sortItems,
    sortItemsByInt,
    sortItemsByIntProperty,
    getFormattedDateTime
};

export default _default;