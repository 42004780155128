import { memberPreferencesActionCreators } from './MemberPreferences.operations';
//import { CommunityGridSortOrder } from './../../screens/Dashboard/components/Communities/CommunityGridSorter';

export interface IMemberPreferencesState {
  /**
   * User preferencs object
   */
  preferences: MemberPreferences | null;

  /**
   * Show preferences panel
   */
  showPreferences: boolean;

  /**
   * Loading state
   */
  isMemberPreferencesLoading: boolean

  /**
   * HTTP error
   */
  error: any;
}

export interface MemberPreferences {
  dashboardIsListMode: boolean;
  //gridSortOrder: CommunityGridSortOrder;
}

export interface IMemberPreferencesProps {
  /**
   * Action creators
   */
  memberPreferencesActionCreators: typeof memberPreferencesActionCreators;
}

export const FETCH_MEMBERPREFERENCES_REQUEST = 'FETCH_MEMBERPREFERENCES_REQUEST';
export const FETCH_MEMBERPREFERENCES_RESPONSE = 'FETCH_MEMBERPREFERENCES_RESPONSE';
export const UPDATE_MEMBERPREFERENCES_REQUEST = 'UPDATE_MEMBERPREFERENCES_REQUEST';
export const UPDATE_MEMBERPREFERENCES_RESPONSE = 'UPDATE_MEMBERPREFERENCES_RESPONSE';
export const SHOW_MEMBERPREFERENCES = 'SHOW_MEMBERPREFERENCES';
export const HIDE_MEMBERPREFERENCES = 'HIDE_MEMBERPREFERENCES';
