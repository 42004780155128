import { notificationActionCreators } from '../../../tabs/components/Notification';
import { progressActionCreators } from '../../../tabs/components/Progress';

import { getGTApiToken } from "../../../common/auth";

import actions from './Invites.actions';
import * as constants from '../../../common/Constants';
import config from '../../../config';

export const invitesActionCreators = {
    requestFetchInvites: (communityId, apiToken: string = null) => async (dispatch, getState) => {
        //if (getState().invites.isLoading) {
        //    return;
        //}

        dispatch({ type: actions.INVITES_FETCH_REQUEST });

        dispatch(
            progressActionCreators.startShowProgress(
                'Fetching community invites...'
            )
        );

        let _apiToken = apiToken;
        if ( !_apiToken ) {
            _apiToken = await getGTApiToken(
                getState().auth.teamsToken
            );
        }

        try {
            const response = await fetch(config.apiBaseUrl + `/invites/${communityId}`, {
                headers: {
                    Authorization: 'Bearer ' + _apiToken,
                    'Content-Type': 'application/json'
                }
            });

            const values = await response.json();
            const pendingInvitations: any[] = [];
            const expiredInvitations: any[] = [];

            values.forEach((invite: any) => {
                if (!invite.expired) {
                    pendingInvitations.push(invite);
                } else {
                    expiredInvitations.push(invite);
                }
            });

            dispatch({
                type: actions.INVITES_FETCH_RESPONSE,
                values,
                pendingInvitations,
                expiredInvitations
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            dispatch(
                notificationActionCreators.startShowNotification(
                    constants.ERROR_INVITES_FETCH
                )
            );
            dispatch({ type: actions.INVITES_FETCH_RESPONSE, error });
            dispatch(progressActionCreators.endShowProgress());
        }
    },
  

    requestDeleteInvite: (inviteId: string) => async (dispatch, getState) => {
        if (getState().invites.isLoading) {
            return;
        }

        dispatch({ type: actions.INVITES_DELETE_REQUEST });

                dispatch(
                    progressActionCreators.startShowProgress('Deleting community invite...')
                );

            const apiToken: string = await getGTApiToken(
                getState().auth.teamsToken
            );

                try {
                    await fetch(
                        config.apiBaseUrl +
                        `/invites/${getState().community.communityId}/${inviteId}`,
                        {
                            method: 'delete',
                            headers: {
                                Authorization: 'Bearer ' + apiToken,
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    /**
                     * Note: Usage of filter in TypeScript requires that the two items being compared are of the same type
                     */
                    const inviteEmailAddress = getState().invites.values.filter(
                        (invite: any) => parseInt(invite.id, 10) === parseInt(inviteId, 10)
                    )[0].emailAddress;

                    const invites = getState().invites.values.filter(
                        (i: any) => parseInt(i.id, 10) !== parseInt(inviteId, 10)
                    );

                    const pendingInvitations: any[] = [];
                    const expiredInvitations: any[] = [];

                    invites.forEach((invite: any) => {
                        if (!invite.expired) {
                            pendingInvitations.push(invite);
                        } else {
                            expiredInvitations.push(invite);
                        }
                    });

                    dispatch({
                        type: actions.INVITES_DELETE_RESPONSE,
                        pendingInvitations,
                        expiredInvitations
                    });
                    dispatch(
                        notificationActionCreators.startShowNotification(
                            'You have successfully removed the invitation for the user ' +
                            inviteEmailAddress
                        )
                    );
                    dispatch(
                        invitesActionCreators.requestFetchInvites(
                            getState().community.communityId
                        )
                    );
                    dispatch(progressActionCreators.endShowProgress());
                } catch (error) {
                    dispatch(
                        notificationActionCreators.startShowNotification(
                            constants.ERROR_INVITES_DELETE
                        )
                    );
                    dispatch({ type: actions.INVITES_DELETE_RESPONSE, error });
                    dispatch(progressActionCreators.endShowProgress());
                }
            

    },

    requestResendInvite: (inviteId: string) => async (dispatch, getState) => {
        if (getState().invites.isLoading) {
            return;
        }

        dispatch({ type: actions.INVITES_RESEND_REQUEST });

                dispatch(
                    progressActionCreators.startShowProgress('Resending community invite...')
                        );

                const apiToken: string = await getGTApiToken(
                    getState().auth.teamsToken
                );

                try {
                    await fetch(
                        config.apiBaseUrl +
                        `/invites/${getState().community.communityId}/${inviteId}`,
                        {
                            method: 'POST',
                            body: JSON.stringify({ resendDate: new Date() }),
                            headers: {
                                Authorization: 'Bearer ' + apiToken,
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    const inviteEmailAddress = getState().invites.values.filter(
                        (invite: any) => parseInt(invite.id, 10) === parseInt(inviteId, 10)
                    )[0].emailAddress;

                    dispatch({ type: actions.INVITES_RESEND_RESPONSE });
                    dispatch(
                        notificationActionCreators.startShowNotification(
                            'You have successfully resent the invitation for the user: ' +
                            inviteEmailAddress
                        )
                    );
                    dispatch(
                        invitesActionCreators.requestFetchInvites(
                            getState().community.communityId
                        )
                    );
                    dispatch(progressActionCreators.endShowProgress());
                } catch (error) {
                    console.dir(error);
                    dispatch(
                        notificationActionCreators.startShowNotification(
                            constants.ERROR_INVITES_RESEND
                        )
                    );
                    dispatch({ type: actions.INVITES_RESEND_RESPONSE, error });
                    dispatch(progressActionCreators.endShowProgress());
                }
    }
};

export default invitesActionCreators;
