export const actions = {
  LOAD: 'COMMUNITY_LOAD',
  COMMUNITY_TNC_ACCEPTED: 'COMMUNITY_TNC_ACCEPTED',
  COMMUNITY_EDIT_START_CREATE: 'COMMUNITY_EDIT_START_CREATE',
  COMMUNITY_EDIT_START: 'COMMUNITY_EDIT_START',
  COMMUNITY_EDIT_END: 'COMMUNITY_EDIT_END',
  COMMUNITY_MANAGE_START: 'COMMUNITY_MANAGE_START',
  COMMUNITY_MANAGE_END: 'COMMUNITY_MANAGE_END',
  COMMUNITY_MANAGE_UPDATE: 'COMMUNITY_MANAGE_UPDATE',
  COMMUNITY_FETCH_REQUEST: 'COMMUNITY_FETCH_REQUEST',
  COMMUNITY_FETCH_RESPONSE: 'COMMUNITY_FETCH_RESPONSE',
  COMMUNITY_SAVE_REQUEST: 'COMMUNITY_SAVE_REQUEST',
  COMMUNITY_SAVE_RESPONSE: 'COMMUNITY_SAVE_RESPONSE',
  COMMUNITY_CLOSE_REQUEST: 'COMMUNITY_CLOSE_REQUEST',
  COMMUNITY_CLOSE_RESPONSE: 'COMMUNITY_CLOSE_RESPONSE',
  COMMUNITY_MEMBERS_SHOW: 'COMMUNITY_MEMBERS_SHOW',
  COMMUNITY_MEMBERS_HIDE: 'COMMUNITY_MEMBERS_HIDE',
  COMMUNITY_UPDATE: 'COMMUNITY_UPDATE',
  COMMUNITY_MEMBERS_EXPORTCSV_REQUEST: 'COMMUNITY_MEMBERS_EXPORTCSV_REQUEST',
  COMMUNITY_MEMBERS_EXPORTCSV_RESPONSE: 'COMMUNITY_MEMBERS_EXPORTCSV_RESPONSE',
  COMMUNITY_INVITES_EXPORTCSV_REQUEST: 'COMMUNITY_INVITES_EXPORTCSV_REQUEST',
  COMMUNITY_INVITES_EXPORTCSV_RESPONSE: 'COMMUNITY_INVITES_EXPORTCSV_RESPONSE',
  COMMUNITIES_FETCH_REQUEST: 'COMMUNITIES_FETCH_REQUEST',
  COMMUNITIES_FETCH_RESPONSE: 'COMMUNITIES_FETCH_RESPONSE',
  COMMUNITIES_SORT: 'COMMUNITIES_SORT',
  OWNERCOMMUNITY_FETCH_REQUEST: 'OWNERCOMMUNITY_FETCH_REQUEST',
  OWNERCOMMUNITY_FETCH_RESPONSE: 'OWNERCOMMUNITY_FETCH_RESPONSE',
  OWNERCOMMUNITY_UPDATE: 'OWNERCOMMUNITY_UPDATE',
  MEMBERCOMMUNITY_FETCH_REQUEST: 'MEMBERCOMMUNITY_FETCH_REQUEST',
  MEMBERCOMMUNITY_FETCH_RESPONSE: 'MEMBERCOMMUNITY_FETCH_RESPONSE',
  FAVOURITECOMMUNITY_FETCH_REQUEST: 'FAVOURITECOMMUNITY_FETCH_REQUEST',
  FAVOURITECOMMUNITY_FETCH_RESPONSE: 'FAVOURITECOMMUNITY_FETCH_RESPONSE',
  MEMBERCOMMUNITY_UPDATE: 'MEMBERCOMMUNITY_UPDATE',
  SUGGESTEDCOMMUNITY_FETCH_REQUEST: 'SUGGESTEDCOMMUNITY_FETCH_REQUEST',
  SUGGESTEDCOMMUNITY_FETCH_RESPONSE: 'SUGGESTEDCOMMUNITY_FETCH_RESPONSE',
  SIMILARCOMMUNITIES_FETCH_REQUEST: 'SIMILARCOMMUNITIES_FETCH_REQUEST',
  SIMILARCOMMUNITIES_FETCH_RESPONSE: 'SIMILARCOMMUNITIES_FETCH_RESPONSE',
  SUGGESTEDCOMMUNITY_UPDATE: 'SUGGESTEDCOMMUNITY_UPDATE',
  COMMUNITY_FORM_DIRTY: 'COMMUNITY_FORM_DIRTY',
  MEMBERTEAMS_FETCH_REQUEST: 'MEMBERTEAMS_FETCH_REQUEST',
  MEMBERTEAMS_FETCH_RESPONSE: 'MEMBERTEAMS_FETCH_RESPONSE',
  COMMUNITY_PANEL_VIEW_START: 'COMMUNITY_PANEL_VIEW_START',
  COMMUNITY_PANEL_VIEW_END: 'COMMUNITY_PANEL_VIEW_END',
  NEWS_FETCH_REQUEST: 'NEWS_FETCH_REQUEST',
  NEWS_FETCH_RESPONSE: 'NEWS_FETCH_RESPONSE'
  
};

export default actions;
