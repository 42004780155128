import { push } from 'react-router-redux';
import { progressActionCreators } from '../../tabs/components/Progress';
import {
    communityActionCreators,
    CommunityRole,
    MemberType,
    ICommunityMember
} from '../CommunityController';

import { notificationActionCreators } from '../../tabs/components/Notification';
import actions from './Members.actions';
import * as constants from '../../common/Constants';
import { getGTApiToken } from "../../common/auth";
import config from '../../config';

export const membersActionCreators = {
    requestFetchMembers: (communityId: string) => async (dispatch, getState) => {
        if (getState().members.isLoading || communityId === null) {
            return;
        }

        dispatch({ type: actions.MEMBERS_FETCH_REQUEST });
        dispatch(
            progressActionCreators.startShowProgress(constants.PROGRESS_MEMBERS_FETCH)
        );

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );

        const allMembers: ICommunityMember[] = [];
        const adminMembers: ICommunityMember[] = [];
        const govMembers: ICommunityMember[] = [];
        const guestMembers: ICommunityMember[] = [];
        const externalMembers: ICommunityMember[] = [];

        try {
            const response = await fetch(config.apiBaseUrl + `/members/${communityId}`, {
                headers: {
                    Authorization: 'Bearer ' + apiToken,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                const error = await response.json();
                throw error;
            }

            const members: ICommunityMember[] = await response.json();

            members.forEach((member: ICommunityMember) => {
                allMembers.push(member);

                switch (member.role) {
                    case CommunityRole.Owner:
                        adminMembers.push(member);
                        break;
                    case CommunityRole.Member:
                        if (member.isGuest) {
                            guestMembers.push(member);
                        } else {
                            if (member.memberType !== MemberType.LAM) {
                                govMembers.push(member);
                            } else {
                                externalMembers.push(member);
                            }
                        }
                        break;
                }
            });

            dispatch({
                type: actions.MEMBERS_FETCH_RESPONSE,
                allMembers,
                adminMembers,
                govMembers,
                guestMembers,
                externalMembers
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            dispatch(
                notificationActionCreators.startShowNotification(constants.ERROR_MEMBERS_FETCH)
            );
            dispatch({
                type: actions.MEMBERS_FETCH_RESPONSE,
                error
            });
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    viewCommunityMembers: (communityId: string) => (dispatch) => {
        dispatch(progressActionCreators.startShowProgress(constants.PROGRESS_MEMBERS_FETCH));
        //microsoftTeams.executeDeepLink("https://users-dev.govteams.gov.au" + `${constants.SCREEN_COMMUNITY}/${communityId}/Members`);
        dispatch(push(`${constants.SCREEN_COMMUNITY}/${communityId}/Members`));
        dispatch(progressActionCreators.endShowProgress());
    },

    requestFetchMembersByRole: (communityId: string, role: string) => async (
        dispatch,
        getState
    ) => {
        if (getState().members.isLoading) {
            return;
        }

        dispatch({ type: actions.MEMBERS_FETCH_BY_ROLE_REQUEST });
        // dispatch(
        //   progressActionCreators.startShowProgress('Fetching community members...')
        // );

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );
                try {
                    const response = await fetch(
                        config.apiBaseUrl + `/members/${communityId}${role ? '/' + role : ''}`,
                        {
                            headers: {
                                Authorization: 'Bearer ' + apiToken,
                                'Content-Type': 'application/json'
                            }
                        }
                    );
                    const members: ICommunityMember[] = await response.json();
                    const allMembers: ICommunityMember[] = [];

                    members.forEach((member: ICommunityMember) => {
                        allMembers.push(member);
                    });

                    dispatch({
                        type: actions.MEMBERS_FETCH_BY_ROLE_RESPONSE,
                        allMembers
                    });
                    // dispatch(progressActionCreators.endShowProgress());
                } catch (error) {
                    dispatch(
                        notificationActionCreators.startShowNotification(
                            constants.ERROR_MEMBERS_FETCH
                        )
                    );
                    dispatch({
                        type: actions.MEMBERS_FETCH_BY_ROLE_RESPONSE,
                        error
                    });
                    // dispatch(progressActionCreators.endShowProgress());
                }
    },

    requestDeleteMember: (
        communityId: string,
        azureId: string,
        isManagePage: boolean
    ) => async (dispatch, getState) => {
        if (getState().members.isLoading) {
            return;
        }

        dispatch({ type: actions.MEMBERS_DELETE_REQUEST });
        dispatch(
            progressActionCreators.startShowProgress(
                constants.PROGRESS_MEMBERS_DELETE
            )
        );

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );

        try {
            const response = await fetch(
                config.apiBaseUrl + `/members/${communityId}/${azureId}`,
                {
                    method: 'delete',
                    headers: {
                        Authorization: 'Bearer ' + apiToken,
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (!response.ok) {
                const error = await response.json();
                throw error;
            }

            dispatch(
                notificationActionCreators.startShowNotification(
                    constants.MESSAGE_MEMBERS_DELETE
                )
            );
            dispatch({ type: actions.MEMBERS_DELETE_RESPONSE });
            dispatch(progressActionCreators.endShowProgress());

            if (isManagePage) {
                dispatch(membersActionCreators.requestFetchMembers(communityId));
            }
            return true;

            // dispatch(
            //     communityActionCreators.updateSuggestedCommunities(communityId)
            // );
        } catch (error) {
            alert(error);
            dispatch({
                type: actions.MEMBERS_DELETE_RESPONSE,
                error
            });
            dispatch(progressActionCreators.endShowProgress());
            return false;
        }
    },

    requestLeaveMember: (
        communityId: string,
        azureId: string,
        isManagePage: boolean
    ) => async (dispatch, getState) => {
        if (getState().members.isLoading) {
            return;
        }

        dispatch({ type: actions.MEMBERS_LEAVE_REQUEST });
        dispatch(
            progressActionCreators.startShowProgress(
                constants.PROGRESS_MEMBERS_LEAVE
            )
        );

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );

        try {
            const response = await fetch(
                config.apiBaseUrl + `/members/${communityId}/${azureId}`,
                {
                    method: 'delete',
                    headers: {
                        Authorization: 'Bearer ' + apiToken,
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (!response.ok) {
                const error = await response.json();
                throw error;
            }

            dispatch(
                notificationActionCreators.startShowNotification(
                    constants.MESSAGE_MEMBERS_LEAVE
                )
            );
            dispatch({ type: actions.MEMBERS_LEAVE_RESPONSE });
            dispatch(progressActionCreators.endShowProgress());
            return true;

            // dispatch(
            //     communityActionCreators.updateSuggestedCommunities(communityId)
            // );
        } catch (error) {
            alert(error);
            dispatch({
                type: actions.MEMBERS_LEAVE_RESPONSE,
                error
            });
            dispatch(progressActionCreators.endShowProgress());
            return false;
        }
    },

    requestChangeMember: (communityId, azureId, isPromote) => async (
        dispatch,
        getState
    ) => {
        if (getState().members.isLoading) {
            return;
        }

        dispatch({ type: actions.MEMBERS_CHANGE_REQUEST });
        dispatch(
            progressActionCreators.startShowProgress(
                isPromote
                    ? constants.PROGRESS_MEMBERS_PROMOTE
                    : constants.PROGRESS_MEMBERS_DEMOTE
            )
        );

        const apiToken: string = await getGTApiToken(
            getState().auth.teamsToken
        );

                try {
                    const response = await fetch(
                        config.apiBaseUrl + `/members/${communityId}/${azureId}/${isPromote}`,
                        {
                            method: 'put',
                            headers: {
                                Authorization: 'Bearer ' + apiToken,
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    if (!response.ok) {
                        const error = await response.json();
                        throw error;
                    }
                    dispatch({
                        type: actions.MEMBERS_CHANGE_RESPONSE
                    });
                    dispatch(progressActionCreators.endShowProgress());
                    dispatch(communityActionCreators.requestFetchCommunity(communityId));
                    dispatch(membersActionCreators.requestFetchMembers(communityId));

                    if (isPromote) {
                        dispatch(
                            notificationActionCreators.startShowNotification(
                                constants.MESSAGE_MEMBERS_PROMOTE
                            )
                        );
                    } else {
                        dispatch(
                            notificationActionCreators.startShowNotification(
                                constants.MESSAGE_MEMBERS_DEMOTE
                            )
                        );
                    }
                } catch (error) {
                    dispatch({
                        type: actions.MEMBERS_CHANGE_RESPONSE,
                        error
                    });
                    dispatch(progressActionCreators.endShowProgress());
                    alert(error);
                }
    }
};

export default membersActionCreators;
