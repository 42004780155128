import { getGTApiToken } from "../../common/auth";
import { notificationActionCreators } from '../../tabs/components/Notification';
import { progressActionCreators } from '../../tabs/components/Progress';
import { communityActionCreators } from '../CommunityController';
import { membersActionCreators } from '../MembersController';


import actions from './Requests.actions';

import * as constants from '../../common/Constants';
import config from '../../config';

export const requestsActionCreators = {
  requestFetchRequests: (communityId, apiToken: string = null) => async (dispatch, getState) => {
    if (getState().requests.isLoading) {
      return;
    }

    dispatch({ type: actions.REQUESTS_FETCH_REQUEST });


        dispatch(
          progressActionCreators.startShowProgress(
            'Fetching community requests...'
          )
        );

        let _apiToken = apiToken;
        if ( !_apiToken ) {
            _apiToken = await getGTApiToken(
                getState().auth.teamsToken
            );
        }

        try {
            const response = await fetch(config.apiBaseUrl + `/requests/${communityId}`, {
            headers: {
              Authorization: 'Bearer ' + _apiToken,
              'Content-Type': 'application/json'
            }
          });
          const json = await response.json();
          if (!response.ok) {
            throw json;
          }
          const values: any[] = [];

          json.forEach((request) => {
            values.push(request);
          });

          dispatch({ type: actions.REQUESTS_FETCH_RESPONSE, values });
          dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
          dispatch(
            notificationActionCreators.startShowNotification(
              constants.ERROR_REQUESTS_FETCH
            )
          );

          dispatch({
            type: actions.REQUESTS_FETCH_RESPONSE,
            error
          });
          dispatch(progressActionCreators.endShowProgress());
        }
  },

  requestChangeRequest: (requestId, isAccepted) => async (
    dispatch,
    getState
  ) => {
    if (getState().requests.isLoading) {
      return;
    }

    dispatch({
      type: actions.REQUESTS_CHANGE_REQUEST
    });

        if (requestId) {
          dispatch(
            progressActionCreators.startShowProgress('Saving change request...')
          );

          //api put does not send false through correctly.
          //sending through as a string.  will be deserialised on the backend.
          let accept = 'false';
          if (isAccepted === true) {
            accept = 'true';
            }

            const apiToken: string = await getGTApiToken(
                getState().auth.teamsToken
            );

          try {
              const response = await fetch(config.apiBaseUrl + `/requests/${requestId}`, {
              method: 'put',
              body: accept,
              headers: {
                Authorization: 'Bearer ' + apiToken,
                'Content-Type': 'application/json'
              }
            });

            if (!response.ok) {
              const error = await response.json();
              throw error;
            }

            dispatch({ type: actions.REQUESTS_CHANGE_RESPONSE });
            dispatch(progressActionCreators.endShowProgress());
            dispatch(
              requestsActionCreators.requestFetchRequests(
                getState().community.communityId
              )
            );
            dispatch(
              membersActionCreators.requestFetchMembers(
                getState().community.communityId
              )
            );
          } catch (error) {
            alert(error);
            dispatch({
              type: actions.REQUESTS_CHANGE_RESPONSE,
              error
            });
            dispatch(progressActionCreators.endShowProgress());
          }
        }

  },

  startJoinMember: (communityId, communityType, isManagePage) => async (
    dispatch,
    getState
  ) => {
    if (communityType === 'Private') {
      dispatch({
        type: actions.REQUESTS_JOIN_START,
        communityId,
        communityType,
        isManagePage
      });
    } else {
      dispatch(
        requestsActionCreators.requestAddMember(
          communityId,
          communityType,
          isManagePage,
          ''
        )
      );
    }
  },

  endJoinMember: (submit, requestMessage) => async (dispatch, getState) => {
    dispatch({
      type: actions.REQUESTS_JOIN_END
    });

    if (submit) {
      dispatch(
        requestsActionCreators.requestAddMember(
          getState().requests.communityId,
          getState().requests.communityType,
          getState().requests.isManagePage,
          requestMessage
        )
      );
    }
  },

  requestAddMember: (
    communityId,
    communityType,
    isManagePage,
    requestMessage
  ) => async (dispatch, getState) => {
    if (getState().requests.isLoading) {
      return;
    }

    dispatch({
      type: actions.REQUESTS_ADD_REQUEST,
      communityType
    });


        if (communityId) {
          dispatch(
            progressActionCreators.startShowProgress('Joining the Community...')
            );

            const apiToken: string = await getGTApiToken(
                getState().auth.teamsToken
            );

          try {
            const response = await fetch(
                config.apiBaseUrl + `/requests/${communityId}`,
              {
                method: 'post',
                body: JSON.stringify({ requestMessage }),
                headers: {
                  Authorization: 'Bearer ' + apiToken,
                  'Content-Type': 'application/json'
                }
              }
            );
            const json = await response.json();
            if (!response.ok) {
              throw json;
            }
            dispatch(notificationActionCreators.startShowNotification(json));

            dispatch({ type: actions.REQUESTS_ADD_RESPONSE });
            dispatch(progressActionCreators.endShowProgress());
            if (communityType === 'Open') {
              dispatch(
                communityActionCreators.updateMemberCommunities(communityId)
              );
              dispatch(
                communityActionCreators.requestFetchCommunity(communityId)
              );
              if (isManagePage) {
                // dispatch(
                //   communityActionCreators.requestFetchCommunity(communityId)
                // );
                dispatch(
                  membersActionCreators.requestFetchMembers(communityId)
                );
              }
            }
          } catch (error) {
            dispatch({
              type: actions.REQUESTS_ADD_RESPONSE,
              error
            });
            dispatch(progressActionCreators.endShowProgress());
          }
        }
  }
};

export default requestsActionCreators;
