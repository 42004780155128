import { getGTApiToken } from "../../common/auth";
import { progressActionCreators } from '../../tabs/components/Progress';
import { invitesActionCreators } from '../../tabs/components/Invites/Invites.operations';
import actions from './InviteMembers.actions';
import config from '../../config';

export const inviteMembersActionCreators = {
  startEditInviteMembers: (communityId) => async (dispatch, getState) => {
    dispatch({
      type: actions.INVITE_MODAL_OPEN,
      communityId
    });
  },

  endEditInviteMembers: (communityId) => async (dispatch, getState) => {
    dispatch({ type: actions.INVITE_MODAL_CLOSE });
  },

  toggleBulkInvite: () => async (dispatch, getState) => {
    dispatch({
      type: actions.INVITE_TOGGLE_BULK_INVITE,
      isBulkInvite: !getState().inviteMembers.isBulkInvite
    });
  },

  requestSaveMembers: (communityId, isBulk) => async (dispatch, getState) => {
    if (getState().inviteMembers.isLoading) {
      return;
    }

    dispatch({ type: actions.INVITE_MEMBERS_SAVE_REQUEST });

      const inviteMembersForm = getState().form.InviteMembersForm.values;

    //const apiToken: string = await adalGetToken(
    //  authContext,
    //  adalConfig.endpoints.api.guid
    //);
      const apiToken: string = await getGTApiToken(
          getState().auth.teamsToken
      );

    dispatch(
      progressActionCreators.startShowProgress('Saving new members...', true)
    );

    try {
      const endpoint = isBulk ? 'invitemembersbulk' : 'invitemembers';
      const target = `${config.apiBaseUrl}/${endpoint}/${communityId}`;
      const members = { members: inviteMembersForm.members };
      const response = await fetch(
        target,
        {
          method: 'put',
          body: JSON.stringify(members),
          headers: {
            Authorization: 'Bearer ' + apiToken,
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw response;
      }

      const json = await response.json();
      const results = json.notifications;
      const isLimitOverdue = false;

      dispatch({
        type: actions.INVITE_MEMBERS_SAVE_RESPONSE,
        isSubmitted: true,
        results,
        isLimitOverdue
      });
      dispatch(
        invitesActionCreators.requestFetchInvites(
          getState().community.communityId
        )
      );
      dispatch(progressActionCreators.endShowProgress());
    } catch (error) {
      const json = await error.json();

      dispatch({
        type: actions.INVITE_MEMBERS_SAVE_RESPONSE,
        isSubmitted: true,
        error: json,
        isLimitOverdue: false
      });
      dispatch(
        invitesActionCreators.requestFetchInvites(
          getState().community.communityId
        )
      );
      dispatch(progressActionCreators.endShowProgress());
    }
  }
};

export default inviteMembersActionCreators;
