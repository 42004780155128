import actions from './Community.actions';
import {ICommunityState} from './Community.types';

export const initialState: ICommunityState = {
    communityId: null,
    initialValues: null,
    ownerCommunities: [],
    memberCommunities: [],
    favouriteCommunities: [],
    suggestedCommunities: [],
    isTnCAccepted: false,
    isSubmitted: false,
    isDirty: false,
    isFormDirty: false,
    isLoading: false,
    isExporting: false,
    isTeamMemberLoading: false,
    isCommunityPanelOpen: false,
    isEditModalOpen: false,
    doShowMembers: false,
    error: null,
    similarCommunities: [],
    isSuggestedLoading: false,
    communityAzureId: null,
    memberTeams: null,
    news: null,
};

export const reducer = (state, action) => {
    state = state || initialState;

    //UI
    if (action.type === actions.COMMUNITY_TNC_ACCEPTED) {
        return {
            ...state,
            isTnCAccepted: true
        };
    }

    if (action.type === actions.COMMUNITY_EDIT_START_CREATE) {
        return {
            ...state,
            initialValues: null,
            isTnCAccepted: false,
            communityId: null,
            isSubmitted: false,
            isFormDirty: false,
            isDirty: false,
            similarCommunities: []
        };
    }

    if (action.type === actions.COMMUNITY_EDIT_START) {
        return {
            ...state,
            isTnCAccepted: true,
            isSubmitted: false,
            isFormDirty: false,
            isDirty: false,
            isEditModalOpen: true,
            initialValues: action.initialValues,
            communityId: action.communityId,
            similarCommunities: null
        };
    }
    if (action.type === actions.COMMUNITY_EDIT_END) {
        return {
            ...state,
            isEditModalOpen: false
        };

    }
    if (action.type === actions.COMMUNITY_MEMBERS_SHOW) {
        return {
            ...state,
            doShowMembers: true
        };
    }
    if (action.type === actions.COMMUNITY_MEMBERS_HIDE) {
        return {
            ...state,
            doShowMembers: false
        };
    }
    //API
    if (action.type === actions.COMMUNITY_FETCH_REQUEST) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === actions.COMMUNITY_FETCH_RESPONSE) {
        return {
            ...state,
            communityId: action.communityId,
            initialValues: action.initialValues,
            isLoading: false,
            isFormDirty: false,
            error: action.error
        };
    }

    if (action.type === actions.COMMUNITY_MEMBERS_EXPORTCSV_REQUEST) {
        return {
            ...state,
            isExporting: true,
        };
    }
    if (action.type === actions.COMMUNITY_MEMBERS_EXPORTCSV_RESPONSE) {
        return {
            ...state,
            isExporting: false,
        };
    }

    if (action.type === actions.NEWS_FETCH_REQUEST) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === actions.NEWS_FETCH_RESPONSE) {
        return {
            ...state,
            news: action.news,
            communityId: action.communityId,
            initialValues: action.initialValues,
            isLoading: false,
            isFormDirty: false,
            error: action.error
        };
    }

    if (action.type === actions.COMMUNITY_SAVE_REQUEST) {
        return {
            ...state,
            isSubmitted: false,
            isLoading: true
        };
    }

    if (action.type === actions.COMMUNITY_SAVE_RESPONSE) {
        return {
            ...state,
            isSubmitted: action.isSubmitted,
            isDirty: action.isDirty,
            isLoading: false,
            isFormDirty: false,
            error: action.error
        };
    }

    if (action.type === actions.COMMUNITY_CLOSE_REQUEST) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === actions.COMMUNITY_CLOSE_RESPONSE) {
        return {
            ...state,
            isDirty: action.isDirty,
            isLoading: false,
            error: action.error
        };
    }

    if (action.type === actions.COMMUNITY_UPDATE) {
        return {
            ...state,
            isDirty: true
        };
    }

    if (action.type === actions.COMMUNITIES_FETCH_REQUEST) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === actions.COMMUNITIES_FETCH_RESPONSE) {
        return {
            ...state,
            ownerCommunities: action.ownerCommunities,
            favouriteCommunities: action.favouriteCommunities,
            memberCommunities: action.memberCommunities,
            isLoading: false,
            error: action.error
        };
    }

    if (action.type === actions.COMMUNITIES_SORT) {
        return {
            ...state,
            ownerCommunities: action.ownerCommunities,
            favouriteCommunities: action.favouriteCommunities,
            memberCommunities: action.memberCommunities
        };
    }

    if (action.type === actions.OWNERCOMMUNITY_FETCH_REQUEST) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === actions.OWNERCOMMUNITY_FETCH_RESPONSE) {
        return {
            ...state,
            ownerCommunities: action.ownerCommunities,
            isLoading: false,
            error: action.error
        };
    }

    if (action.type === actions.OWNERCOMMUNITY_UPDATE) {
        return {
            ...state,
            ownerCommunities: action.ownerCommunities
        };
    }

    if (action.type === actions.MEMBERCOMMUNITY_FETCH_REQUEST) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === actions.MEMBERCOMMUNITY_FETCH_RESPONSE) {
        return {
            ...state,
            memberCommunities: action.memberCommunities,
            isDirty: false,
            isLoading: false,
            error: action.error
        };
    }

    if (action.type === actions.FAVOURITECOMMUNITY_FETCH_REQUEST) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === actions.FAVOURITECOMMUNITY_FETCH_RESPONSE) {
        return {
            ...state,
            favouriteCommunities: action.favouriteCommunities,
            isDirty: false,
            isLoading: false,
            error: action.error
        };
    }

    if (action.type === actions.MEMBERCOMMUNITY_UPDATE) {
        return {
            ...state,
            memberCommunities: action.memberCommunities,
            suggestedCommunities: action.suggestedCommunities
        };
    }

    if (action.type === actions.SUGGESTEDCOMMUNITY_FETCH_REQUEST) {
        return {
            ...state,
            isSuggestedLoading: true
        };
    }

    if (action.type === actions.SUGGESTEDCOMMUNITY_FETCH_RESPONSE) {
        return {
            ...state,
            suggestedCommunities: action.suggestedCommunities,
            isLoading: false,
            error: action.error
        };
    }

    if (action.type === actions.SIMILARCOMMUNITIES_FETCH_REQUEST) {
        return {
            ...state,
            isSuggestedLoading: true
        };
    }

    if (action.type === actions.SIMILARCOMMUNITIES_FETCH_RESPONSE) {
        return {
            ...state,
            similarCommunities: action.similarCommunities,
            isSuggestedLoading: false,
            error: action.error
        };
    }

    if (action.type === actions.SUGGESTEDCOMMUNITY_UPDATE) {
        return {
            ...state,
            suggestedCommunities: action.suggestedCommunities,
            memberCommunities: action.memberCommunities
        };
    }

    if (action.type === actions.COMMUNITY_FORM_DIRTY) {
        return {
            ...state,
            isFormDirty: true
        };
    }

    if (action.type === actions.MEMBERTEAMS_FETCH_REQUEST) {
        return {
            ...state,
            isTeamMemberLoading: true,
            memberTeams: null
        };
    }

    if (action.type === actions.MEMBERTEAMS_FETCH_RESPONSE) {
        return {
            ...state,
            isTeamMemberLoading: false,
            memberTeams: action.memberTeams
        };
    }

    if (action.type === actions.COMMUNITY_PANEL_VIEW_START) {
        return {
            ...state,
            communityId: action.communityId,
            initialValues: action.initialValues,
            isCommunityPanelOpen: true
        };
    }

    if (action.type === actions.COMMUNITY_PANEL_VIEW_END) {
        return {
            ...state,
            isCommunityPanelOpen: false
        };
    }

    if (action.type === actions.COMMUNITY_MANAGE_START) {
        return {
            ...state,
            communityId: action.communityId,
            initialValues: action.initialValues,
            isCommunityPanelOpen: false
        };
    }

    if (action.type === actions.COMMUNITY_MANAGE_END) {
        return {
            ...state,
            isCommunityPanelOpen: false,
            initialValues: null
        };
    }

    if (action.type === actions.COMMUNITY_MANAGE_UPDATE) {
        return {
            ...state,
            initialValues: action.initialValues
        };
    }

    return state;
};

export default reducer;
