import * as React from 'react';
import { connect } from 'react-redux';
import { IPersonaProps } from '@fluentui/react/lib/Persona';

import PeoplePickerComponent from '@govteams/library/components/PeoplePickerComponent/';

import { getGTApiGraphToken } from '../../../common/auth';
import { BRANDNAME } from '../../../common/Tooltips';

const NormalPeoplePickerComponent = props => {
    const { ariaLabel, label, disabled, input, auth } = props;
    const [suggestionsHeaderText, setSuggestionsHeaderText] = React.useState<string>('Suggested people');

    const onChange = (items) => {
        const emails = items ? items.map( item => {
            const obj = { emailAddress: item.secondaryText };
            if ( item.id && item.id !== '' ) {
                obj['azureId'] = item.id;
            }
            return obj;
        }) : [];
        input.onChange(emails);
    };

    const onResolveSuggestions = (
        filterText: string,
        currentPersonas: IPersonaProps[],
        limitResults?: number
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            return new Promise<IPersonaProps[]>((resolve) =>
                getGTApiGraphToken(auth.teamsToken).then(
                    (token: any) => {
                        const microsoftGraph = require('@microsoft/microsoft-graph-client');
                        const client = microsoftGraph.Client.init({
                            authProvider: (done: any) => {
                                done(null, token);
                            }
                        });
                        // filter text must have apostrophes escaped by switching them into double single quotes ('')
                        // https://docs.microsoft.com/en-us/graph/query-parameters#escaping-single-quotes
                        const escapedFilterText = filterText.replace(/'/g, "''");

                        client
                            .api('/users/')
                            .filter(`startswith(displayName,'${escapedFilterText}') or startswith(surname,'${escapedFilterText}')`)
                            .select(
                                'id,displayName,mail,userPrincipalName,jobTitle,department,userType,accountEnabled'
                            )
                            .get((error: any, response: any) => {
                                const suggestedPeople: IPersonaProps[] = [];
                                if (response) {
                                    for (const user in response.value) {

                                        // if (!/#EXT#/.test(response.value[user].userPrincipalName)) {
                                        if (response.value[user]) {
                                            if (response.value[user].accountEnabled === false) {
                                                continue;
                                            }
                                            const tertiaryInfo: string[] = [];

                                            if (response.value[user].jobTitle) {
                                                tertiaryInfo.push(response.value[user].jobTitle);
                                            }

                                            if (response.value[user].department) {
                                                tertiaryInfo.push(response.value[user].department);
                                            }

                                            const person = {
                                                id: response.value[user].id,
                                                primaryText: response.value[user].displayName,
                                                secondaryText: response.value[user].mail,
                                                tertiaryText: tertiaryInfo.join(', ')
                                            };

                                            suggestedPeople.push(person);
                                        }
                                    }
                                }

                                if (suggestedPeople.length > 0) {
                                    setSuggestionsHeaderText('Suggested people');
                                    resolve(suggestedPeople);
                                } else {
                                    setSuggestionsHeaderText('');
                                    resolve([
                                        {
                                            primaryText: filterText,
                                            secondaryText: filterText
                                        }
                                    ]);
                                }
                            });
                    }
                )
            ).then((persons) =>
                persons.filter(
                    (person) => !listContainsPersona(person, currentPersonas)
        )
            );
        } else {
            return [];
        }
    };

    const listContainsPersona = (persona: IPersonaProps, personas: IPersonaProps[]): boolean => {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return (
            personas.filter((item) => item.secondaryText === persona.secondaryText).length > 0
        );
    };

    return (
        <PeoplePickerComponent
            noResultsFoundText={'we couldn\'t find any members'}
            onChange={onChange}
            disabled={disabled}
            tooltipAriaLabel={ariaLabel}
            tooltipLabel={label}
            suggestionsHeaderText={suggestionsHeaderText}
            onResolveSuggestions={onResolveSuggestions}
            brandName={BRANDNAME}
        />
    );
}


const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
});

const NormalPeoplePicker = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NormalPeoplePickerComponent);

export default NormalPeoplePicker;
