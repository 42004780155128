import config from '../config';

export const getGTApiToken = (token) : Promise<string> => {
    
    // Generic error handler ( avoids having to do async fetch in try/catch block )
    // const unhandledFetchError = (err: string) => {
    //     console.error('Unhandled fetch error: ', err);
    //     throw new Error('Error: ' + err);
    //     //setError('Unhandled fetch error: ' + err);
    // };
    
    // const serverURL = `${config.apiBaseUrl}/getGTAccessToken?ssoToken=${token}`;
    const serverURL = `https://${config.publicHostname as string}/GetUserAccessToken`;
    // console.log('here ' + serverURL);

    return fetch(serverURL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'content-Type': 'application/json'
        })
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`HTTP error. Response status: ${response.status}`);
        }
        return response.json();
    })
    .then((data) => {
        // console.log(data);

        //check govteams response and handle accordingly
        return(data.access_token);

    })
    .catch((err) => {
        console.log(err.message);
    })
    .finally(
    );
};


const fetchToken = (token, resolve) => {

    // Generic error handler ( avoids having to do async fetch in try/catch block )
    // const unhandledFetchError = (err: string) => {
    //     console.error('Unhandled fetch error: ', err);
    //     throw new Error('Error: ' + err);
    //     //setError('Unhandled fetch error: ' + err);
    // };

    // const serverURL = `${config.apiBaseUrl}/getGTAccessToken?ssoToken=${token}`;
    const serverURL = `https://${config.publicHostname as string}/GetUserAccessToken`;
    console.log('here ' + serverURL);

    fetch(serverURL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'content-Type': 'application/json'
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error. Response status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            console.log(data);

            //check govteams response and handle accordingly
            resolve(data.access_token);
        })
        .catch((err) => {
            console.log(err.message);
        })
        .finally(
        );
};


export const getGTApiGraphToken = (token): Promise<string> => {

    // Generic error handler ( avoids having to do async fetch in try/catch block )
    // const unhandledFetchError = (err: string) => {
    //     console.error('Unhandled fetch error: ', err);
    //     throw new Error('Error: ' + err);
    //     //setError('Unhandled fetch error: ' + err);
    // };

    // const serverURL = `${config.apiBaseUrl}/getGTAccessToken?ssoToken=${token}`;
    const serverURL = `https://${config.publicHostname as string}/GetUserAccessGraphToken`;
    // console.log('here ' + serverURL);

    return fetch(serverURL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'content-Type': 'application/json'
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error. Response status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            // console.log(data);

            //check govteams response and handle accordingly
            return (data.access_token);

        })
        .catch((err) => {
            console.log(err.message);
        })
        .finally(
        );
};


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchGraphToken = (token, resolve) => {

    // Generic error handler ( avoids having to do async fetch in try/catch block )
    // const unhandledFetchError = (err: string) => {
    //     console.error('Unhandled fetch error: ', err);
    //     throw new Error('Error: ' + err);
    //     //setError('Unhandled fetch error: ' + err);
    // };

    // const serverURL = `${config.apiBaseUrl}/getGTAccessToken?ssoToken=${token}`;
    const serverURL = `https://${config.publicHostname as string}/GetUserAccessGraphToken`;
    console.log('here ' + serverURL);

    fetch(serverURL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'content-Type': 'application/json'
        })
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error. Response status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            console.log(data);

            //check govteams response and handle accordingly
            resolve(data.access_token);
        })
        .catch((err) => {
            console.log(err.message);
        })
        .finally(
        );
};

export const getGTApiTokenAsync = (token) => {
    let promise = new Promise((resolve, reject) => {
        fetchToken(token, resolve);
    })
    
    return promise;
};