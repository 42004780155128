/**
 * Community members screen
 *
 * Display a sortable list of all members in a community
 *
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  inviteMembersActionCreators,
} from '../../../controllers/InviteMembersController';
import {
  communityActionCreators,
} from '../../../controllers/CommunityController';
import {
  membersActionCreators,
} from '../../../controllers/MembersController';

/**
 * GovTEAMS components
 */
import CommunityMemberPersona from '../CommunityMemberPersona';

/**
 * GovTEAMS components
 */
import CommunityMembersCommonComponent from '@govteams/library/components/Community/CommunityMembersComponent/';
import { isProtected } from '../../../common/Utils';

const CommunityMembersComponent = (props) => {
  const {
    handleBackClick,
    allMembers,
    initialValues,
    communityAzureId,
    profile,
    results,
    inviteMembersActionCreators,
    communityActionCreators,
    membersActionCreators,
  } = props;

  return (
      <CommunityMembersCommonComponent
          isProtected={isProtected()}
          allMembers={allMembers}
          community={initialValues}
          match={null}
          communityAzureId={communityAzureId}
          profile={profile}
          results={results}
          handleBackClick={handleBackClick}
          inviteMembersActionCreators={inviteMembersActionCreators}
          communityActionCreators={communityActionCreators}
          membersActionCreators={membersActionCreators}
          CommunityMemberPersona={CommunityMemberPersona}
      />
  );
}

const mapStateToProps = (state) => ({
  allMembers: state.members.allMembers,
  initialValues: state.community.initialValues,
  profile: state.profile,
  results: state.inviteMembers.results
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  inviteMembersActionCreators: bindActionCreators(
    inviteMembersActionCreators,
    dispatch
  ),
  communityActionCreators: bindActionCreators(
    communityActionCreators,
    dispatch
  ),
  membersActionCreators: bindActionCreators(membersActionCreators, dispatch)
});

export const CommunityMembers = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityMembersComponent);

export default CommunityMembers;
