import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as reduxFormReducer } from 'redux-form';


/**
 * Component reducers
 */
import { reducer as community } from '../controllers/CommunityController/Community.reducers';
import { reducer as references } from '../controllers/ProfileController/References';
import { reducer as progress } from '../tabs/components/Progress/Progress.reducers';
import { reducer as requests } from '../controllers/RequestsController/Requests.reducers';
import { reducer as inviteMembers } from '../controllers/InviteMembersController/InviteMembers.reducers';
import { reducer as memberPreferences } from '../controllers/MemberPreferencesController/MemberPreferences.reducers';

/**
 * Screen reducers
 */
import { reducer as invites } from '../tabs/components/Invites/Invites.reducers';
import { reducer as auth } from '../controllers/Auth/auth.reducers';
import { reducer as members } from '../controllers/MembersController';
import { reducer as notification } from '../tabs/components/Notification';

const reducers = {

  //    announcements,
  community,
  form: reduxFormReducer,
  invites,
  inviteMembers,
  members,
  notification,
  progress,
  references,
  requests,
  memberPreferences,
  auth,
};

const rootReducer = combineReducers({
  ...reducers,
  routing: routerReducer
});

export default rootReducer;

