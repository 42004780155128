/**
 * Community tools
 *
 * Provides functionality to manage and access community features.
 * Used on community panel and community management screen.
 *
 */

/**
 * React
 */
import * as React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
// import { pages } from '@microsoft/teams-js';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { inviteMembersActionCreators } from './../../../controllers/InviteMembersController';
import { requestsActionCreators } from '../../../controllers/RequestsController';
import { communityActionCreators } from './../../../controllers/CommunityController';
import { membersActionCreators } from './../../../controllers/MembersController';
import { authActionCreators } from '../../../controllers/Auth';

/**
 * Child components
 */
import CommunityActionsCommonComponent from '@govteams/library/components/ManageCommunity/CommunityActionsComponent/';

/**
 * Constants
 */
import * as constants from './CommunityActions.constants';
import config from '../../../config';

const CommunityActionsComponent = props => {

  const {
    community,
    showMembersList,
    showManagementTools,
    panelDismiss,
    isExporting,
    hasMemberLeft,
    profile,
    userAzureId,
    communityActionCreators,
    inviteMembersActionCreators,
    membersActionCreators,
    requestsActionCreators,
  } = props;
  const _profile = {
    ...profile,
    id: userAzureId,
  };

  React.useEffect(() => {
    if (!community && panelDismiss) {
      panelDismiss();
    }
  }, [community, panelDismiss]);

  React.useEffect(() => {
    if (hasMemberLeft) {
      console.log('Member has left', hasMemberLeft);
      setTimeout(reloadManageTab, 3000);
    }
  }, [hasMemberLeft]);

  const viewCommunityMembers = () => {
    showMembersList(true);
  };

  const reloadManageTab = () => {
    microsoftTeams?.app?.openLink(config.govTeamsTab)
      .then(null);

    /*if (pages.currentApp.isSupported()) {
      pages.currentApp.navigateTo({ pageId: 'manage', subPageId: '' })
        .then(null)
        .catch((error) => console.log('Navigation Failed', error));
    }
    else {
      pages.navigateToApp({
        appId: config.applicationId, pageId: 'manage'
      })
        .then(null)
        .catch((error) => console.log('Navigation Failed', error));
    }*/
  };

  return (
    <div className='govTeams-communityActions'>
      <CommunityActionsCommonComponent
        community={community}
        profile={_profile}
        constants={constants}
        showManagementTools={showManagementTools}
        isExporting={isExporting}

        closeCommunityPanel={communityActionCreators.closeCommunityPanel}
        requestCloseCommunity={communityActionCreators.requestCloseCommunity}
        requestLeaveCommunity={membersActionCreators.requestLeaveMember}
        startEditInviteMembers={inviteMembersActionCreators.startEditInviteMembers}
        requestViewCommunityMembers={viewCommunityMembers}
        requestDeleteMember={membersActionCreators.requestDeleteMember}
        startEditCommunity={communityActionCreators.startEditCommunity}
        exportCommunityMemberCsv={communityActionCreators.exportCommunityMemberCsv}
        exportCommunityInvitesCsv={communityActionCreators.exportCommunityInvitesCsv}
        startJoinMember={requestsActionCreators.startJoinMember}
        startEditManage={communityActionCreators.startEditManage}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  community: state.community.initialValues,
  isExporting: state.community.isExporting,
  favouriteCommunities: state.community.favouriteCommunities,
  isLeaving: state.members.isLeaving,
  hasMemberLeft: state.members.hasMemberLeft,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  authActionCreators: bindActionCreators(authActionCreators, dispatch),
  inviteMembersActionCreators: bindActionCreators(inviteMembersActionCreators, dispatch),
  requestsActionCreators: bindActionCreators(requestsActionCreators, dispatch),
  membersActionCreators: bindActionCreators(membersActionCreators, dispatch),
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch)
});

export const CommunityActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityActionsComponent);

export default CommunityActions;
