import actions from './auth.actions';

export const initialState: any = {
    teamsToken: '',
    apiToken: '',
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === actions.SETTEAMSTOKEN) {
        return {
            ...state,
            teamsToken: action.payload
        };
    }
    if (action.type === actions.SETAPITOKEN) {
        return {
            ...state,
            apiToken: action.payload
        };
    }
    return state;
};

export default reducer;