/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Fabric } from '@fluentui/react/lib/Fabric';
import { useState, useEffect } from 'react';
import { useTeams } from 'msteams-react-base-component';
import * as microsoftTeams from '@microsoft/teams-js';
import {
    Provider,
    Flex
} from '@fluentui/react-northstar';
import { Provider as ReduxProvider } from 'react-redux';
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";

import { inviteMembersActionCreators } from '../controllers/InviteMembersController';
import { requestsActionCreators } from '../controllers/RequestsController';
import { communityActionCreators } from '../controllers/CommunityController';
import { invitesActionCreators } from './components/Invites';
import { authActionCreators } from '../controllers/Auth';
import CommunityManagement from './components/CommunityManagement';
import { Progress } from './components/Progress';

import configureStore from '../store/configureStore';
import config from '../config';

export const history = createBrowserHistory();
export const store = configureStore(history);

/**
 * Implementation of the Createacommunity content page
 */
export const ManageCommunityTab = () => {
    const [{ inTeams, theme }] = useTeams();
    const [context, setContext] = useState<microsoftTeams.app.Context|undefined>(null);
    const [accessToken, setAccessToken] = useState<string>('');
    const [communityAzureId, setCommunityAzureId] = useState<string>();
    const [userAzureId, setUserAzureId] = useState<string>();
    const [memberType, setMemberType] = useState<string>();
    const [entityId, setEntityId] = useState<string | undefined>();
    const [/*error*/, setError] = useState<string>();


    const tokenSettings = {
        teamsToken: accessToken,        //current teams access token that we can use to exchange for an API access token
        setTeamsToken: setAccessToken,    //NOT USED: pass in setter method to allow us to refresh the teams access token in a component if required
    };

    const _getTeamsContext = async () => {
        if (inTeams === true) {
            microsoftTeams.authentication.getAuthToken({
                resources: [config.tabAppUri as string]
            })
                .then( (token: string) => {
                    setAccessToken(token);
                    authActionCreators.storeTeamsToken(token);
                    microsoftTeams.app.notifySuccess();
                    microsoftTeams.app.getContext()
                        .then( (_context: microsoftTeams.app.Context) => {
                            setContext(_context);
                        });
                })
                .catch( (message: string) => {
                    setError(message);
                    microsoftTeams.app.notifyFailure({
                        reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
                        message
                    });
                    console.log('Teams authentication failed: ', message);
                });
        } else {
            setEntityId('Not in Microsoft Teams');
        }
    };

    useEffect(() => {
        _getTeamsContext().then(null);
    }, [inTeams]);

    useEffect(() => {
        if (context) {
            setEntityId(context.page?.id);
            setCommunityAzureId(context.team?.groupId?.toString());
            setUserAzureId(context.user?.id?.toString());
            setMemberType(context.user?.userPrincipalName?.toString().replace(/.*@/, '').split('.')[0].toUpperCase())
            //const memberType = context.userPrincipalName?.toString().replace(/.*@/, '').split('.')[0];
        }
    }, [context]);

    /**
     * The render() method to create the UI of the tab
     */
    return (
        <Provider theme={theme}>
            <ReduxProvider store={store}>
                <Fabric>
                    <Progress />
                    {!!accessToken && !!communityAzureId && (
                        <Flex fill={false} column className='container' styles={{
                            padding: '.8rem 1rem .8rem 1rem'
                        }}>
                            <Flex.Item>
                                <CommunityManagement
                                    entityId={entityId}
                                    context={context}
                                    teamsToken={accessToken}
                                    communityAzureId={communityAzureId}
                                    userAzureId={userAzureId}
                                    memberType={memberType}
                                />
                            </Flex.Item>
                        </Flex>
                    )}
                    {!(accessToken && communityAzureId) && (
                        <Spinner size={SpinnerSize.small} title={'Loading...'} />
                    )}
                </Fabric>
            </ReduxProvider>
        </Provider>
    );
};


const mapStateToProps = (state) => ({
    azureId: state.community.communityAzureId,
    isUserInTeam: state.community.isUserInTeam,
    pendingInvitations: state.invites.pendingInvitations
});

const mapDispatchToProps = (dispatch) => ({
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
    communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
    invitesActionCreators: bindActionCreators(invitesActionCreators, dispatch),
    inviteMembersActionCreators: bindActionCreators(inviteMembersActionCreators, dispatch),
    requestsActionCreators: bindActionCreators(requestsActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageCommunityTab);