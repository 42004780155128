/**
 * Form to invite members to community
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators } from '../../../../controllers/CommunityController';
import { inviteMembersActionCreators } from '../../../../controllers/InviteMembersController';

/**
 * React form field
 */
import InviteMembersBulkComponent from '@govteams/library/components/InviteMembersBulkComponent/';

/**
 * Constants
 */
import * as constants from '../../../../common/Constants';

const InviteMembersBulkForm = props => {
  const {
    communityId,
    isSubmitted,
    initialValues,

    communityActionCreators,
    inviteMembersActionCreators,
  } = props;

  return (
    <>
      <InviteMembersBulkComponent
        communityId={communityId}
        isSubmitted={isSubmitted}
        initialValues={initialValues}
        constants={constants}

        requestFetchCommunity={communityActionCreators.requestFetchCommunity}
        requestSaveMembers={inviteMembersActionCreators.requestSaveMembers}
        endEditInviteMembers={inviteMembersActionCreators.endEditInviteMembers}
        toggleBulkInvite={inviteMembersActionCreators.toggleBulkInvite}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  communityId: state.community.communityId,
  initialValues: state.community.initialValues,
  isSubmitted: state.inviteMembers.isSubmitted,
  results: state.inviteMembers.results,
  isLimitOverdue: state.inviteMembers.isLimitOverdue
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
  inviteMembersActionCreators: bindActionCreators(
    inviteMembersActionCreators,
    dispatch
  )
});

const InviteMembersBulk = connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteMembersBulkForm);

export default InviteMembersBulk;
